import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
@Component({
  selector: "app-revision-note-component",
  templateUrl: "./revision-note-component.component.html",
  styleUrls: ["./revision-note-component.component.css"],
})
export class RevisionNoteComponentComponent implements OnInit {
  url = this.dataService.serverUrl;
  pdfUrl;
  @Input() rowData: any;
  constructor(
    public dataService: DataService,
    public domSanitizer: DomSanitizer
  ) {}
  ngOnInit() {
    this.pdfUrl = this.domSanitizer.bypassSecurityTrustUrl(
      this.url + "uploads/revisionNotes/" + this.rowData.fileName
    );
  }
}

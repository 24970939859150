import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-slider-video',
  templateUrl: './slider-video.component.html',
  styleUrls: ['./slider-video.component.css']
})
export class SliderVideoComponent implements OnInit {
  videoURL
@Input () rowData:any
  constructor( public domSanitizer:DomSanitizer) { }

  ngOnInit() {



    this.videoURL=   this.domSanitizer.bypassSecurityTrustUrl(this.rowData.youtubeLink)

    console.log('youtube video id',this.videoURL)



  }

}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    public router: Router,
    public dataService: DataService,
    public http: HttpClient
  ) {}

  ngOnInit() {}

  submit() {
    // alert(this.password)
    // if (this.dataService.password != "123") {
    //   alert("Wrong password");
    //   return;
    // }

    this.http
      .get(this.dataService.serverUrl + "adminlogin", {
        params: { password: this.dataService.password + "" },
      })
      .toPromise()
      .then((data: any) => {
        if (data.status) {
          this.dataService.login();
          // window.localStorage.setItem("login", "1");

          this.router.navigate(["/dashboard"]);
        } else {
          alert("Wrong Password");
        }
      })
      .catch((err) => {
        alert("server err");
        return false;
      });
  }
}

import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-book-test",
  templateUrl: "./book-test.component.html",
  styleUrls: ["./book-test.component.css"]
})
export class BookTestComponent implements OnInit {
  tests = []
  cities = []
  selectedCityID;
  courses = []
  selectedCourseID;
  subjects = [];
  selectedSubjectID;
  centers = [];
  selectedCenterID;
  timeSlot;
  date;
  amount;

  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    hideSubHeader: true,
    actions: {
      edit: false,
      position: "right"
    },
    columns: {
      city: {
        title: "city"
      },
      center: {
        title: "Center "
      },
      course: {
        title: "Course"
      },
      subject: {
        title: "Subject"
      },
      timeSlot: {
        title: "Time"
      },
      date: {
        title: "Date"
      },
      amount: {
        title: "Amount"
      }
    }
  };

  constructor(public http: HttpClient, public dataService: DataService) {
    this.getTests()
    this.getCities()
    this.getCourses()
    // this.getSubjects();

    // console.log(this.dataService.courses);

    // this.dataService.coursesUpdated.subscribe(data => {
    //   this.courses = this.dataService.courses;
    // });

  }

  getCities() {
    this.http.get(this.dataService.serverUrl + 'city', {}).toPromise().then((data: any) => {
      console.log(data);
      this.cities = data;

    })
  }

  getCourses() {
    this.http.get(this.dataService.serverUrl + "course", {}).toPromise().then((data: any) => {
      console.log(data);
      this.courses = data;


    })
  }

  getSubjects(courseID) {
    this.http
      .get(this.dataService.serverUrl + "subject?courseID=" + courseID, {})
      .toPromise()
      .then((data: Array<any>) => {
        this.subjects = data;

        // // update the service courses
        // this.dataService.courses = data;
        // // emit the event coursesUpdate
        // this.dataService.coursesUpdated.emit();
        // console.log(this.dataService.courses);
        // this.courses = this.dataService.courses;
      })
      .catch(err => {
        alert("Connection Error");
        console.log(err);
      });
  }



  getCenters(cityID) {
    this.http.get(this.dataService.serverUrl + "center?cityID=" + cityID, {}).toPromise().then((data: any) => {
      console.log(data);
      this.centers = data;

    })
  }

  getTests() {
    this.http.get(this.dataService.serverUrl + "test", {}).toPromise().then((data: any) => {
      console.log(data)
      this.tests = data
    })
  }
  submitTest() {
    this.http.post(this.dataService.serverUrl + "test", {


      cityID: this.selectedCityID + "",
      courseID: this.selectedCourseID + "",
      subjectID: this.selectedSubjectID + "",
      centerID: this.selectedCenterID + "",
      timeSlot: this.timeSlot + "",
      date: this.date + "",
      amount: this.amount + ""

    }).toPromise().then((data: any) => {

      console.log(data);

      this.getTests()

    })
  }
  // submitSubject() {
  //   // console.log("submitting");

  //   this.http
  //     .post(this.dataService.serverUrl + "subject", {
  //       courseID: this.selectedCourseID + "",
  //       subject: this.subject
  //     })
  //     .toPromise()
  //     .then((data: any) => {
  //       console.log(data);

  //       if (data.status === "success") {
  //         this.getSubjects();
  //       }
  //     })
  //     .catch(err => {
  //       alert("Connection Error");
  //       console.log(err);
  //     });
  // }
  ngOnInit() { }



  onDeleteConfirm(event) {
    console.log("Delete Event In Console");
    console.log(event.data.id);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + "test/" + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {
            // alert("City Deleted !");
            // this.getCourses();
            event.confirm.resolve();
            return;
          }
          event.confirm.reject();

          alert("Unable to delete the course");
        })
        .catch(err => {
          event.confirm.reject();

          alert("Connection Error");
          console.log(JSON.stringify(err));
        });
    } else {
    }
  }

  citySelected(cityID) {
    // alert(cityID)
    this.getCenters(cityID)
  }

  courseSelected(courseID) {
    // alert(courseID)
    this.getSubjects(courseID)
  }
}

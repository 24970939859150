import { Component, OnInit, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { DataService } from 'src/app/services/data.service';
import { HttpClient } from '@angular/common/http';
import { UploadReportButtonComponent } from 'src/app/components/upload-report-button/upload-report-button.component';
import { ViewReportButtonComponentComponent } from 'src/app/components/view-report-button-component/view-report-button-component.component';
// tslint:disable-next-line: max-line-length
import { DownloadReportButtonComponentComponent } from 'src/app/components/download-report-button-component/download-report-button-component.component';
import { ResultUploadComponent } from 'src/app/components/result-upload/result-upload.component';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {


  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    hideSubHeader: true,
    actions: false,
    columns: {
      city: {
        title: "City"
      },
      center: {
        title: "Center "
      },
      course: {
        title: "Course"
      },
      subject: {
        title: "Subject"
      },
      timeSlot: {
        title: "Time"
      },
      date: {
        title: "Date"
      },
      // amount: {
      //   title: "Amount"
      // },
      upload: {
        title: "Upload Result ",
        type: "custom",
        renderComponent: ResultUploadComponent,

      }
    }
  };
  studentSettings = {

    hideSubHeader: true,
    actions: false,
    columns: {
      studentName: {
        title: "Name"
      },
      phone: {
        title: "Phone "
      },



      upload: {
        title: "Upload ",
        type: "custom",
        renderComponent: UploadReportButtonComponent,

      },
      download: {
        title: "Download ",
        type: "custom",
        renderComponent: DownloadReportButtonComponentComponent,

      }
    }
  };

  tests = [];
  students = [

  ]

  constructor(public http: HttpClient, public dataService: DataService) {
    this.getTests()
    // this.getStudents(1)

    this.dataService.report.testSelected.subscribe(testID => {
      // this.courses = this.dataService.courses;
      // alert(data)
      // data is the testID
      this.getStudents(testID)
    });


  }
  getTests() {
    this.http.get(this.dataService.serverUrl + "test", {}).toPromise().then((data: any) => {
      console.log(data)
      this.tests = data
    })
  }
  ngOnInit() {
  }

  getStudents(testID) {

    this.http.get(this.dataService.serverUrl + "report/" + testID, {}).toPromise().then((data: any) => {
      if (!data.status) {
        alert("Error gettting Students")
        return
      }

      console.log(data);
      this.students = data.data


    }).catch(err => {
      console.log(err)
      alert("Connection Error")
    })

  }

  onDeleteConfirm(event) { }

}


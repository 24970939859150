import {
  HttpClient
} from '@angular/common/http';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  DataService
} from 'src/app/services/data.service';

import { Ng2SmartTableModule,LocalDataSource } from "ng2-smart-table";

@Component({
  selector: 'app-add-test-question',
  templateUrl: './add-test-question.component.html',
  styleUrls: ['./add-test-question.component.css']
})
export class AddTestQuestionComponent implements OnInit {


  pdfFile: FileList;
  selectedSubCourseID;
  selectedSubjectID;
  selectedChapterID;
  filteredSubjects = [];
  filteredChapters = [];
  pdfs = [];
  videos = [];
  pdfTitle;
  videoTitle = "";
  youtubeLink = "";
  noteTitle = "";
  fileToUpload;
  notes = [];

  slidersTestimonial = [];
  slidersVideo = [];

  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      text: {
        title: "Text",
      },
      'option 1': {
        title: "Option 1",
      },
      'option 2': {
        title: "Option 2",
      },
      'option 3': {
        title: "Option 3",
      },
      'option 4': {
        title: "Option 4",
      },
      'correctOption': {
        title: "Correct Option",
      },

    },
  };





  textQuestion = ""
  firstOption = ""
  secondOption = ""
  thirdOption = ""
  fourthOption = ""
  correctOption
  quizImage



  public quizChapterQuestions = new LocalDataSource();

  // quizChapterQuestions = []

  constructor(public http: HttpClient, public dataService: DataService) {}

  ngOnInit() {}


  quizImageSelected(files: FileList) {
    this.quizImage = files;
  }

  submitQuestion() {
    if (!this.selectedChapterID) {
      return
    }
    let formData = new FormData();
    let fileToUpload
    if (this.quizImage) {
      fileToUpload = this.quizImage.item(0);
      formData.append("quizImage", fileToUpload, fileToUpload.name);
    }
    // this.fileToUpload = files.item(0);
    formData.append("chapterID", this.selectedChapterID);
    formData.append("textQuestion", this.textQuestion);
    formData.append("firstOption", this.firstOption);
    formData.append("secondOption", this.secondOption);
    formData.append("thirdOption", this.thirdOption);
    formData.append("fourthOption", this.fourthOption);
    formData.append("correctOption", this.correctOption);


    this.http
      .post(this.dataService.serverUrl + "quiz/question", formData)
      .subscribe((val: any) => {
        console.log(val);

        if (!val.status) {
          alert("Unable to add question");
        } else {
          this.getQuizChapterQuestion();
        }
      });
    return false;
  }


  getQuizChapterQuestion() {

    this.http
      .get(this.dataService.serverUrl + "quiz/question/chapter/"+this.selectedChapterID, {
      })
      .subscribe((data: any) => {
        console.log("GET QUIZ QUESTION CHAPTER :", data);

        // this.quizChapterQuestions = data.data;
        this.quizChapterQuestions.load(data.data)

      });
  }

  getFilteredSubjects() {
    this.http
      .get(this.dataService.serverUrl + "subject", {
        params: { subCourseID: this.selectedSubCourseID },
      })
      .subscribe((data: any) => {
        console.log("GET FILTERED SUBJECT :", data);

        this.filteredSubjects = data.data;
      });
  }

  getFilteredChapters() {
    this.http
      .get(this.dataService.serverUrl + "chapter", {
        params: { subjectID: this.selectedSubjectID },
      })
      .subscribe((data: any) => {
        console.log("GET FILTERED CHAPTER :", data);

        this.filteredChapters = data.data;
      });
  }

  deleteQuestion(event) {



    console.log("Delete Event In Console");
    console.log(event);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + `quiz/question/` + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {


            this.getQuizChapterQuestion()
            // event.confirm.resolve();
            return;
          }
          // event.confirm.reject();
          alert("Unable to delete the slider");
        })
        .catch((err) => {
          event.confirm.reject();
          alert("Connection Error");
          console.log(JSON.stringify(err));
        });
    } else {
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.css']
})
export class ViewVideoComponent implements OnInit {

  youtubeLink;

  @Input() rowData: any
  constructor() { }

  ngOnInit() {
    this.youtubeLink = this.rowData.youtubeLink;
  }

}

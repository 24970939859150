import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-view-library-pdf',
  templateUrl: './view-library-pdf.component.html',
  styleUrls: ['./view-library-pdf.component.css']
})
export class ViewLibraryPdfComponent implements OnInit {
  url
  @Input() rowData: any
  constructor(public dataService: DataService) {


  }

  ngOnInit() {
    // if (this.rowData.source == 'important')
    //   this.url = this.dataService.serverUrl + 'uploads/important/' + this.rowData.name
    // else
    this.url = this.dataService.serverUrl + `uploads/${this.rowData.source}/` + this.rowData.name
  }

}

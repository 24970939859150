import { Component, OnInit, Input } from '@angular/core';
import { } from 'ng2-smart-table';
import { DataService } from 'src/app/services/data.service';
import { HttpClient } from '@angular/common/http';
import { UploadReportButtonComponent } from 'src/app/components/upload-report-button/upload-report-button.component';
import { ViewReportButtonComponentComponent } from 'src/app/components/view-report-button-component/view-report-button-component.component';
// tslint:disable-next-line: max-line-length
import { DownloadReportButtonComponentComponent } from 'src/app/components/download-report-button-component/download-report-button-component.component';
import { VideoUploadComponent } from 'src/app/components/video-upload/video-upload.component';
import { PdfSolutionUploadComponent } from 'src/app/components/pdf-solution-upload/pdf-solution-upload.component';
import { GetQuestionsComponent } from 'src/app/components/get-questions/get-questions.component';
import { QuestionEditorComponent } from 'src/app/components/question-editor/question-editor.component';
import { QuestionNumberComponent } from 'src/app/components/question-number/question-number.component';
import { UploadSolutionCSVComponent } from 'src/app/components/upload-solution-csv/upload-solution-csv.component';
import { GetSolutionsComponent } from 'src/app/components/get-solutions/get-solutions.component';
import { QuestionUploadVideoComponent } from 'src/app/components/question-upload-video/question-upload-video.component';
import { QuestionUpdateNumberComponent } from 'src/app/components/question-update-number/question-update-number.component';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  // showQuestions = true
  showQuestions = false
  selectedTestID
  questionSettings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    hideSubHeader: true,
    actions: false,
    columns: {
      // idx: {
      //   title: "Question Number",
      //   type: "text",
      //   filter: false,
      //   valuePrepareFunction: (row) => {
      //     //   console.log(row); 
      //     //   console.log(row.index);
      //     return `${row.type}`;
      //   }
      // },

      questionNumber: {
        title: "Ques. Number",
        type: "custom",
        renderComponent: QuestionUpdateNumberComponent,

      },
      value: {
        title: "Ques. Image",
        type: "custom",
        renderComponent: QuestionEditorComponent,

      },
      uploadSolution: {
        title: "Solution Video",
        type: "custom",
        renderComponent: QuestionUploadVideoComponent,

      },
      // upload: {
      //   title: "Question Text ",
      //   type: "custom",
      //   renderComponent: QuestionEditorComponent,

      // },
      // upload1: {
      //   title: "Solution URL",
      //   type: "custom",
      //   renderComponent: UploadReportButtonComponent,

      // }

    }
  };
  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    hideSubHeader: true,
    actions: false,
    columns: {
      city: {
        title: "City"
      },
      center: {
        title: "Center "
      },
      // course: {
      //   title: "Course"
      // },
      subject: {
        title: "Subject"
      },
      timeSlot: {
        title: "Time"
      },
      date: {
        title: "Date"
      },
      upload: {
        title: "Upload CSV ",
        type: "custom",
        renderComponent: UploadSolutionCSVComponent,

      },
      show: {
        title: "Solutions",
        type: "custom",
        renderComponent: GetQuestionsComponent,
      }
      // amount: {
      //   title: "Amount"
      // },
      // upload: {
      //   title: "Video Solution ",
      //   type: "custom",
      //   renderComponent: VideoUploadComponent,

      // },
      // uploadPDF: {
      //   title: "PDF Solution ",
      //   type: "custom",
      //   renderComponent: PdfSolutionUploadComponent,

      // },
    }
  };

  tests = [];
  questions = []

  constructor(public http: HttpClient, public dataService: DataService) {
    this.getTests()
    // this.getStudents(1)

    this.dataService.getQuestionsEvent.subscribe(data => {
      console.log('got data in video component', data);
      this.showQuestions = true
      this.questions = data.data
      console.log('question', this.questions);


      // this.courses = this.dataService.courses;
      // alert(data)
      // data is the testID
      // this.getQuestions(testID)

      // call the service to get all the questions for the particular test id
    });


  }
  getTests() {

    this.http.get(this.dataService.serverUrl + "test", {}).toPromise().then((data: any) => {
      console.log(data)
      this.tests = data
    })
  }
  getQuestions(testID) {
    this.selectedTestID = testID
    this.http.get(this.dataService.serverUrl + "question?testID=" + testID, {}).toPromise().then((data: any) => {
      console.log('questions Data', data)

      if (!data.status) {
        alert("Error getting questions !")
        return
      }

      this.showQuestions = true

      this.questions = data.data
    })
  }
  ngOnInit() {
  }



  onDeleteConfirm(event) { }


}

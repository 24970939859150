import { Component, OnInit } from "@angular/core";
import { NbMenuItem } from "@nebular/theme";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  items: NbMenuItem[] = [
    {
      title: "Masters",
      icon: "edit-2",
      link: "masters",
    },
    {
      title: "Uploads",
      icon: "image-outline",
      link: "uploads",
      // link: "slider",
    },

    // {
    //   title: "Students",
    //   icon: "file-text-outline",
    //   link: "report",
    // },
    // {
    //   title: "Upload Solution",
    //   icon: "checkmark-square-outline",
    //   link: "solution",
    // },
    // {
    //   title: "Publish Result",
    //   icon: "trending-up-outline",
    //   link: "result",
    // },
    // {
    //   title: "Change Password",
    //   icon: "lock-outline",
    //   // link: []
    // },

    {
      title: "Logout",
      icon: "corner-down-left-outline",
      link: "../",
    },
  ];
  constructor() {}

  ngOnInit() {}
}

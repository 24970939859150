import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-test-report",
  templateUrl: "./test-report.component.html",
  styleUrls: ["./test-report.component.css"],
})
export class TestReportComponent implements OnInit {
  data = [];

  finalList = [];

  constructor(
    public dataService: DataService,
    public http: HttpClient,
    public route: ActivatedRoute
  ) {
    this.getData(this.route.snapshot.params.testId);
  }

  getData(testId) {
    this.http
      .get(this.dataService.strapiServerUrl + "student-test-questions", {
        params: {
          "testQuestionID.testID": testId + "",
          _limit: 10000 + "",
        },
      })
      .subscribe((data: any) => {
        this.data = data;
        this.rankCalculator();
      });
  }
  rankCalculator() {
    this.data.forEach((element) => {
      let studentAdded = this.studentExists(element.studentID.id);
      if (studentAdded.found) {
        this.finalList[studentAdded.index].marks += element.marks;
      } else {
        this.finalList.push({
          student: element.studentID,
          marks: element.marks,
        });
      }
    });
    this.finalList.sort((a, b) => b.marks - a.marks);
    console.log(this.finalList);
  }

  ngOnInit() {}

  studentExists(studentId) {
    var found = false;
    var index = 0;
    for (var i = 0; i < this.finalList.length; i++) {
      if (this.finalList[i].student.id == studentId) {
        found = true;
        index = i;
        break;
      }
    }
    return { found, index };
  }
}

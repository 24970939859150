import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-question-upload-video',
  templateUrl: './question-upload-video.component.html',
  styleUrls: ['./question-upload-video.component.css']
})
export class QuestionUploadVideoComponent implements OnInit {

  // public Editor = Editor;
  @ViewChild('editor', null) editor: ElementRef

  data = 'abc'
  @Input('rowData') rowData;


  fileToUpload
  // imageExtension

  constructor(public dataService: DataService, public http: HttpClient) { }

  ngOnInit() {
    // console.log('editory el', this.editor);

    console.log("rowdata of individual question for video solution", this.rowData)


    // CKEDITOR.replace(this.editor.nativeElement);

    // ClassicEditor
    //   .create(document.querySelector('#editor'))
    //   .then(editor => {
    //     console.log(editor);
    //   })01
    //   .catch(error => {
    //     console.error(error);
    //   });
  }
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );
  }



  postMethod(files: FileList) {
    // alert("asdf")
    this.fileToUpload = files.item(0);
    let formData = new FormData();
    console.log(this.fileToUpload);

    // alert()

    // this.imageExtension = this.fileToUpload.name.split('.')[this.fileToUpload.name.split('.').length - 1]


    formData.append('file', this.fileToUpload, `${this.rowData.id}.${'mp4'}`);
    // formData.append('file', this.fileToUpload, `${this.rowData.id}.${this.imageExtension}`);
    // formData.append('abc', "def");

    this.http.post(this.dataService.serverUrl + "solution/upload/solution", formData).subscribe((val: any) => {

      console.log(val);

      if (val.status) {
        alert("Video Solution Uploaded !");
        return
      }
      alert("Failed to Upload Video  !")
    });
    return false;
  }



}

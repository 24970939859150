import { Component, OnInit } from "@angular/core";
import { SliderImageComponent } from "src/app/components/slider-image/slider-image.component";
import { HttpClient } from "@angular/common/http";
import { DataService } from "src/app/services/data.service";
import { SliderThumbnailComponent } from "src/app/components/slider-thumbnail/slider-thumbnail.component";
import { SliderVideoComponent } from "src/app/components/slider-video/slider-video.component";
import { SliderImageTestimonialComponent } from "src/app/components/slider-image-testimonial/slider-image-testimonial.component";
import { RevisionNoteComponentComponent } from "src/app/components/revision-note-component/revision-note-component.component";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.css"],
})
export class SliderComponent implements OnInit {
  youtubeLink = "";
  noteTitle = "";
  fileToUpload;
  notes = [];
  sliders = [];
  slidersTestimonial = [];
  slidersVideo = [];

  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    add: {
      addButtonContent: "",
    },
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      sliderImage: {
        title: "Slider Image",
        type: "custom",
        renderComponent: SliderImageComponent,
      },
    },
  };
  settingsNotes = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    add: {
      addButtonContent: "",
    },
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      title: {
        title: "Tittle",
      },

      sliderImage: {
        title: "Slider Image",
        type: "custom",
        renderComponent: RevisionNoteComponentComponent,
      },
    },
  };
  settingsTestimonial = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    add: {
      addButtonContent: "",
    },
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      sliderTestimonial: {
        title: "Slider Image",
        type: "custom",
        renderComponent: SliderImageTestimonialComponent,
      },
    },
  };
  settingsVideo = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    add: {
      addButtonContent: "",
    },
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      thumbnail: {
        title: "Thumbnail Image (.png)",
        type: "custom",
        renderComponent: SliderThumbnailComponent,
      },
      video: {
        title: "Youtube Link ",
        type: "custom",
        renderComponent: SliderVideoComponent,
      },
    },
  };
  constructor(public http: HttpClient, public dataService: DataService) {
    this.getSliders();
    // this.getSliderVideos();
    // this.getSlidersTestimonials();
    // this.getNotes();
  }

  ngOnInit() { }

  postMethod(files: FileList) {
    this.fileToUpload = files.item(0);
    let formData = new FormData();
    formData.append("file", this.fileToUpload, this.fileToUpload.name);

    this.http
      .post(this.dataService.serverUrl + "slider/uploadImage", formData)
      .subscribe((val: any) => {
        console.log(val);

        if (!val.status) {
          alert("Unable to upload slider");
        } else {
          this.getSliders();
        }
      });
    return false;
  }

  postMethodTestimonial(files: FileList) {
    this.fileToUpload = files.item(0);
    let formData = new FormData();
    formData.append("file", this.fileToUpload, this.fileToUpload.name);

    this.http
      .post(
        this.dataService.serverUrl + "slider/uploadImageTestimonial",
        formData
      )
      .subscribe((val: any) => {
        console.log(val);

        if (!val.status) {
          alert("Unable to upload slider");
        } else {
          this.getSlidersTestimonials();
        }
      });
    return false;
  }

  postMethodNote(files: FileList) {
    this.fileToUpload = files.item(0);
    let formData = new FormData();

    formData.append("file", this.fileToUpload, this.fileToUpload.name);
    formData.append("noteTitle", this.noteTitle);

    this.http
      .post(this.dataService.serverUrl + "note/upload", formData)
      .subscribe((val: any) => {
        console.log("post note/upload response ", val);

        if (!val.status) {
          alert("Unable to upload note");
        } else {
          this.getNotes();
        }
      });
    return false;
  }

  postMethodVideo(files: FileList) {
    this.fileToUpload = files.item(0);
    let formData = new FormData();
    formData.append("file", this.fileToUpload, this.fileToUpload.name);
    formData.append("youtubeLink", this.youtubeLink);
    this.http
      .post(this.dataService.serverUrl + "slider/uploadVideo", formData)
      .subscribe((val: any) => {
        console.log(val);

        if (!val.status) {
          alert("Unable to upload video");
        } else {
          this.getSliderVideos();
        }
      });
    return false;
  }

  getSliders() {
    this.http
      .get(this.dataService.serverUrl + "slider")
      .toPromise()
      .then((data: any) => {
        console.log("slider images", data);

        if (!data.status) {
          alert("Error getting sliders");
          return;
        }

        this.sliders = data.data;
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }
  getNotes() {
    this.http
      .get(this.dataService.serverUrl + "note")
      .toPromise()
      .then((data: any) => {
        console.log("get note response ", data);

        if (!data.status) {
          alert("Error getting sliders");
          return;
        }

        this.notes = data.data;
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }
  getSlidersTestimonials() {
    this.http
      .get(this.dataService.serverUrl + "slider/testimonial")
      .toPromise()
      .then((data: any) => {
        console.log("slider images", data);

        if (!data.status) {
          alert("Error getting testimonial");
          return;
        }

        this.slidersTestimonial = data.data;
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }
  getSliderVideos() {
    this.http
      .get(this.dataService.serverUrl + "slider/video")
      .toPromise()
      .then((data: any) => {
        console.log("slider videos", data);

        if (!data.status) {
          alert("Error getting sliders");
          return;
        }

        this.slidersVideo = data.data;
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }

  onDeleteConfirm(event, deleteRevisionNote = false) {
    let endpoint = "slider";

    if (deleteRevisionNote) {
      endpoint = "note";
    }

    console.log("Delete Event In Console");
    console.log(event);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + `${endpoint}/` + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {
            // alert("City Deleted !");
            this.getSliders();

            event.confirm.resolve();
            return;
          }
          event.confirm.reject();
          alert("Unable to delete the slider");
        })
        .catch((err) => {
          event.confirm.reject();
          alert("Connection Error");
          console.log(JSON.stringify(err));
        });
    } else {
    }
  }
}

import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-free-videos",
  templateUrl: "./free-videos.component.html",
  styleUrls: ["./free-videos.component.css"],
})
export class FreeVideosComponent implements OnInit {
  videoSettings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      name: {
        title: "Video Name",
      },
      youtubeLink: {
        title: "Video Link",
      },
      // sliderImage: {
      //   title: "Link Video",
      //   type: "custom",
      //   renderComponent: ViewVideoComponent,
      // },
    },
  };

  data = [];
  name = "";
  youtubeLink = "";
  constructor(public http: HttpClient, public dataService: DataService) {
    this.getVideos();
  }

  ngOnInit() {}

  postVideo() {
    this.http
      .post(this.dataService.serverUrl + "freeVideos", {
        name: this.name,
        youtubeLink: this.youtubeLink,
      })
      .subscribe((data: any) => {
        console.log("POST FREE VIDEO", data);
        this.getVideos();
      });
  }

  getVideos() {
    this.http
      .get(this.dataService.serverUrl + "freeVideos")
      .subscribe((data: any) => {
        console.log("GET FREE VIDEOS", data);
        this.data = data.data;
      });
  }
  deleteVideo(event) {
    console.log(event);
    this.http
      .delete(this.dataService.serverUrl + "freeVideos/" + event.data.id)
      .subscribe((data: any) => {
        console.log("DELETE FREE VIDEOS", data);
        this.getVideos();
      });
  }
}

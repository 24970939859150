import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-upload-report-button',
  templateUrl: './upload-report-button.component.html',
  styleUrls: ['./upload-report-button.component.css']
})
export class UploadReportButtonComponent implements OnInit {
  @ViewChild("uploadForm", { static: false }) uploadForm: ElementRef

  filesToUpload: Array<File>;
  fileToUpload
  @Input() rowData: any;

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;


  constructor(public dataService: DataService, private fb: FormBuilder, public http: HttpClient) {
    this.filesToUpload = [];
    console.log(this.rowData);


  }

  ngOnInit() {

  }



  // onFileChange(event) {
  //   let reader = new FileReader();
  //   if (event.target.files && event.target.files.length > 0) {
  //     let file = event.target.files[0];
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       this.form.get('avatar').setValue({
  //         filename: file.name,
  //         filetype: file.type,
  //         value: reader.result.split(',')[1]
  //       })
  //     };
  //   }
  // }

  postMethod(files: FileList) {
    this.fileToUpload = files.item(0);
    let formData = new FormData();


    formData.append('file', this.fileToUpload, `${this.rowData.id}.pdf`);
    formData.append('abc', "def");

    this.http.post(this.dataService.serverUrl + "report/upload", formData).subscribe((val: any) => {

      console.log(val);

      if (val.status) {
        alert("Report Uploaded !");
        return
      }
      alert("Failed to Upload Report  !")
    });
    return false;
  }




  // upload() {
  //   // alert("sadf")
  //   console.log(this.uploadForm);
  //   this.uploadForm.nativeElement.submit()

  // }

}

import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-subject",
  templateUrl: "./subject.component.html",
  styleUrls: ["./subject.component.css"],
})
export class SubjectComponent implements OnInit {
  selectedSubCourseID;

  subject;
  amount;

  //NOT USING THESE RIGHT NOW

  // firstInstallmentDate;
  // firstInstallmentAmount;
  // secondInstallmentDate;
  // secondInstallmentAmount;
  fullInstallmentDate;
  fullInstallmentAmount;

  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: true,
    actions: {
      edit: true,
      position: "right",
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      cancelButtonContent: '<i class="nb-close-circled"></i>',
      saveButtonContent: '<i class="nb-checkmark-circle"></i>',

      confirmSave: true,
    },
    editor: {},
    columns: {
      subCourseName: {
        editable: false,

        title: "Subcourse ",
      },
      name: {
        editable: false,

        title: "Subject ",
      },
      // amount: {
      //   title: "Amount",
      // },
      // firstInstallmentDate: {
      //   title: "1st Instal. Dt.",
      // },
      // firstInstallmentAmount: {
      //   title: "1st Instal. Amt.",
      // },
      // secondInstallmentDate: {
      //   title: "2nd Instal. Dt.",
      // },
      // secondInstallmentAmount: {
      //   title: "2nd Instal. Amt.",
      // },
      fullInstallmentDate: {
        title: "Full Instal. Dt.",
      },
      fullInstallmentAmount: {
        title: "Full Instal. Amt.",
      },
    },
  };

  constructor(public http: HttpClient, public dataService: DataService) {}

  submitSubject() {
    // console.log("submitting");
    // alert(this.firstInstallmentDate);
    // alert(this.firstInstallmentAmount);

    this.http
      .post(this.dataService.serverUrl + "subject", {
        subCourseID: this.selectedSubCourseID + "",
        subject: this.subject,
        // amount: this.amount,
        // firstInstallmentAmount: this.firstInstallmentAmount,
        // firstInstallmentDate: this.firstInstallmentDate,
        // secondInstallmentAmount: this.secondInstallmentAmount,
        // secondInstallmentDate: this.secondInstallmentDate,
        fullInstallmentAmount: this.fullInstallmentAmount,
        fullInstallmentDate: this.fullInstallmentDate,
      })
      .toPromise()
      .then((data: any) => {
        console.log(data);

        if (data.status) {
          this.dataService.getSubjects();
        }
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }
  ngOnInit() {}

  onEdit(event) {
    if (window.confirm("Are you sure you want to save?")) {
      // call to remote api, remember that you have to await this
      event.confirm.resolve(event.newData);

      console.log(event);

      this.http
        .patch(this.dataService.serverUrl + "subject/" + event.newData.id, {
          ...event.newData,
        })
        .subscribe(
          (data: any) => {
            console.log("PATCH SUBJECT ", data);
            if (!data.status) {
              alert("Failed to Update");
              this.dataService.getSubjects();
              return;
            }
          },
          (error) => {
            console.log("ERROR PATCH SUBJECT ", error);
            alert("Connection Error");

            event.confirm.reject();
          }
        );
    } else {
    }
  }

  onDeleteConfirm(event) {
    console.log("Delete Event In Console");
    console.log(event);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + "subject/" + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {
            // alert("City Deleted !");
            this.dataService.getSubjects();
            event.confirm.resolve();
            return;
          }
          event.confirm.reject();

          alert("Unable to delete the course");
        })
        .catch((err) => {
          event.confirm.reject();

          alert("Connection Error");
          console.log(JSON.stringify(err));
        });
    } else {
    }
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-slider-image-testimonial",
  templateUrl: "./slider-image-testimonial.component.html",
  styleUrls: ["./slider-image-testimonial.component.css"],
})
export class SliderImageTestimonialComponent implements OnInit {
  url = this.dataService.serverUrl;
  @Input() rowData: any;

  constructor(public dataService: DataService) {}

  ngOnInit() {}
}

import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { resolve } from "url";

@Injectable()
export class DataService {
  password = "";
  loggedIn = false;
  environment = "prod";

  cities = [];
  courses = [];
  subCourses = [];
  subjects = [];
  chapters = [];

  subscription = {
    selectedStudentID: undefined,
    selectedStudentName: "",
    studentSubjects: [],
    studentTutorialViews: [],
  };

  citiesUpdated = new EventEmitter();
  coursesUpdated = new EventEmitter();
  report = {
    testSelected: new EventEmitter(),
  };

  getQuestionsEvent = new EventEmitter();
  serverUrl;
  strapiServerUrl;

  // dev to prod
  // 1. change serverUrl
  // 2. change node db connection

  constructor(public http: HttpClient) {
    // this.environment = "dev";
    if (this.environment === "dev") {
      this.serverUrl = "http://localhost:8080/";
      this.strapiServerUrl = "http://localhost:1337/";
    } else if (this.environment === "prod") {
      // this.serverUrl = "https://admin.awacademy.in/";
      // this.serverUrl = "https://akash.webmediaindia.com/";
      this.serverUrl = "https://api.knowlify.in/";
      // this.serverUrl = "https://knowlify.webmediaindia.com/";

      if (this.serverUrl.includes("akash")) {
        this.strapiServerUrl = "https://strapi-akash.webmediaindia.com/";
      } else if (this.serverUrl.includes("knowlify")) {
        this.strapiServerUrl = "https://strapi.knowlify.in/";
      }

      // this.strapiServerUrl = "http://localhost:1337/";
    }

    this.getCourses();
    this.getSubCourses();
    this.getSubjects();
    this.getChapters();
  }
  isAuthenticated() {
    const promise = new Promise((resolve, reject) => {
      resolve(this.loggedIn);
    });
    return promise;
  }
  login() {
    this.loggedIn = true;
  }
  logout() {
    this.loggedIn = false;
  }
  getCourses() {
    this.http.get(this.serverUrl + "course").subscribe((data: any) => {
      console.log("GET COURSE :", data);

      this.courses = data.data;
    });
  }
  getSubCourses() {
    this.http.get(this.serverUrl + "subcourse").subscribe((data: any) => {
      console.log("GET SUBCOURSE :", data);

      this.subCourses = data.data;
    });
  }
  getSubjects() {
    this.http.get(this.serverUrl + "subject").subscribe((data: any) => {
      console.log("GET SUBJECT :", data);

      this.subjects = data.data;
    });
  }
  getChapters() {
    this.http.get(this.serverUrl + "chapter").subscribe((data: any) => {
      console.log("GET CHAPTER :", data);

      this.chapters = data.data;
    });
  }

  getStudentSubjects() {
    // NOW GET THE SUBSCRITPION DATA
    this.http
      .get(
        this.serverUrl + "subscription/" + this.subscription.selectedStudentID
      )
      .subscribe(
        (data: any) => {
          console.log("GET SUBSCRIPTION ", data);
          this.subscription.studentSubjects = data.data;
          document
            .getElementById("manageSubscriptionContainer")
            .scrollIntoView();
        },
        (error) => {
          console.log("ERROR GET SUBSCRIPTION ", error);
        }
      );
  }
  getStudentTutorialViews(studentId) {
    this.http
      .get(this.strapiServerUrl + "student-tutorials", {
        params: {
          "student.id": studentId + "",
        },
      })
      .subscribe((data: any) => {
        data.forEach((element) => {
          console.log(element);

          element.studentName = element.student.name;
          element.tutorialName = element.tutorial.title;
        });

        this.subscription.studentTutorialViews = data;
      });
  }

  // console.log(dateRange("2021-01-01", "2021-12-20"));

  dateRange(startDate, endDate) {
    let start = startDate.split("-");
    let end = endDate.split("-");
    let startYear = parseInt(start[0]);
    let endYear = parseInt(end[0]);
    let dates = [];

    for (let i = startYear; i <= endYear; i++) {
      let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        let month = j + 1;
        let displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    return dates;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-test-master',
  templateUrl: './book-test-master.component.html',
  styleUrls: ['./book-test-master.component.css']
})
export class BookTestMasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

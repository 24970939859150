import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-question-update-number',
  templateUrl: './question-update-number.component.html',
  styleUrls: ['./question-update-number.component.css']
})
export class QuestionUpdateNumberComponent implements OnInit {
  // public Editor = Editor;
  @ViewChild('editor', null) editor: ElementRef

  @Input('rowData') rowData;


  fileToUpload
  // imageExtension

  constructor(public dataService: DataService, public http: HttpClient) { }

  ngOnInit() {
    // console.log('editory el', this.editor);

    // console.log("rowdata of individual question", this.rowData)


    // CKEDITOR.replace(this.editor.nativeElement);

    // ClassicEditor
    //   .create(document.querySelector('#editor'))
    //   .then(editor => {
    //     console.log(editor);
    //   })01
    //   .catch(error => {
    //     console.error(error);
    //   });
  }
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );
  }

  valueChanged() {
    // alert('asdf')
    this.http.post(this.dataService.serverUrl + "solution/updateQuestionNumber", { questionID: this.rowData.id, questionNumber: this.rowData.questionNumber }).toPromise().then(data => {

      console.log('question number updated', data);


    }).catch(err => {
      alert("Unable to update question Number !")
      console.log(err);

    })
  }

  postMethod(files: FileList) {
    // alert("asdf")
    this.fileToUpload = files.item(0);
    let formData = new FormData();
    console.log(this.fileToUpload);

    // alert()

    // this.imageExtension = this.fileToUpload.name.split('.')[this.fileToUpload.name.split('.').length - 1]


    formData.append('file', this.fileToUpload, `${this.rowData.id}.${'jpg'}`);
    // formData.append('file', this.fileToUpload, `${this.rowData.id}.${this.imageExtension}`);
    // formData.append('abc', "def");

    this.http.post(this.dataService.serverUrl + "solution/upload/question", formData).subscribe((val: any) => {

      console.log(val);

      if (val.status) {
        alert("Report Uploaded !");
        return
      }
      alert("Failed to Upload Report  !")
    });
    return false;
  }



}

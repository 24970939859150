import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "src/app/services/data.service";
import { SliderThumbnailComponent } from "src/app/components/slider-thumbnail/slider-thumbnail.component";
import { SliderVideoComponent } from "src/app/components/slider-video/slider-video.component";
import { SliderImageTestimonialComponent } from "src/app/components/slider-image-testimonial/slider-image-testimonial.component";
import { RevisionNoteComponentComponent } from "src/app/components/revision-note-component/revision-note-component.component";
import { ViewLibraryPdfComponent } from "../view-library-pdf/view-library-pdf.component";
import { ViewVideoComponent } from "../view-video/view-video.component";

@Component({
  selector: "app-live",
  templateUrl: "./live.component.html",
  styleUrls: ["./live.component.css"],
})
export class LiveComponent implements OnInit {
  selectedSubCourseID;
  selectedSubjectID;
  selectedChapterID;
  filteredSubjects = [];
  filteredChapters = [];
  videos = [];

  name;
  date;
  time;
  youtubeLink = "";

  videoSettings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    // hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      subcourseName: {
        title: "Sub Course",
      },
      subjectName: {
        title: "Subject",
      },
      name: {
        title: "Class Title",
      },
      date: {
        title: "Date",
      },
      time: {
        title: "Time",
      },

      youtubeLink: {
        title: "Link",
      },
      // sliderImage: {
      //   title: "Link Video",
      //   type: "custom",
      //   renderComponent: ViewVideoComponent,
      // },
    },
  };
  constructor(public http: HttpClient, public dataService: DataService) {
    this.getVideos();
  }

  getFilteredSubjects() {
    this.http
      .get(this.dataService.serverUrl + "subject", {
        params: { subCourseID: this.selectedSubCourseID },
      })
      .subscribe((data: any) => {
        console.log("GET FILTERED SUBJECT :", data);

        this.filteredSubjects = data.data;
      });
  }
  getFilteredChapters() {
    this.http
      .get(this.dataService.serverUrl + "chapter", {
        params: { subjectID: this.selectedSubjectID },
      })
      .subscribe((data: any) => {
        console.log("GET FILTERED CHAPTER :", data);

        this.filteredChapters = data.data;
      });
  }

  ngOnInit() {}

  getVideos() {
    this.http
      .get(this.dataService.serverUrl + "live")
      .toPromise()
      .then((data: any) => {
        console.log("GET LIVE", data);

        if (!data.status) {
          alert("Error getting sliders");
          return;
        }

        this.videos = data.data;
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }
  addVideo() {
    // alert("asdf");
    this.http
      .post(this.dataService.serverUrl + "live", {
        subjectID: this.selectedSubjectID,
        name: this.name,
        date: this.date,
        time: this.time,
        youtubeLink: this.youtubeLink,
      })
      .subscribe(
        (data: any) => {
          console.log("POST LIBRARY", data);
          this.getVideos();
        },
        (error) => {
          alert("Error Adding Video");
        }
      );
  }

  deleteData(event) {
    console.log("Delete Event In Console");
    console.log(event);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + `live/` + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);
          if (data.status) {
            // this.getVideos();
            event.confirm.resolve();
            return;
          }

          event.confirm.reject();
          alert("Unable to delete the live class");
        })
        .catch((err) => {
          event.confirm.reject();
          alert("Connection Error");
          console.log(JSON.stringify(err));
        });
    } else {
    }
  }
}

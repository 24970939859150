import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-view-test-result-button",
  templateUrl: "./view-test-result-button.component.html",
  styleUrls: ["./view-test-result-button.component.css"],
})
export class ViewTestResultButtonComponent implements OnInit {
  @Input() rowData: any;
  constructor() {}

  ngOnInit() {}
  viewResult() {
    window.open(
      window.location.origin + "/testreport/" + this.rowData.id,
      "_blank"
    );
  }
}
